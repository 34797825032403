import React from "react";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";

const headersocials = () => {
  return (
    <div className="header_socials">
      <a href="https://linkedin.com" target="_blank" rel="noreferrer">
        <AiFillLinkedin />
      </a>
      <a href="https://instagram.com" target="_blank" rel="noreferrer">
        <AiFillInstagram />
      </a>
      <a href="https://facebook.com" target="_blank" rel="noreferrer">
        <AiFillFacebook />
      </a>
      <a href="https://x.com" target="_blank" rel="noreferrer">
        <AiFillTwitterCircle />
      </a>
    </div>
  );
};

export default headersocials;
