import React from "react";
import "./footer.css";
import { CiFacebook } from "react-icons/ci";
import { BsInstagram } from "react-icons/bs";
import { TiSocialTwitterCircular } from "react-icons/ti";
import { AiOutlineLinkedin } from "react-icons/ai";

const footer = () => {
  return (
    <footer>
      <a href="#" className="footer_logo">
        {" "}
        VIJETH NARAYAN{" "}
      </a>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="footer_socials">
        <a href="https://facebook.com">
          <CiFacebook />
        </a>
        <a href="https://instagram.com.com">
          <BsInstagram />
        </a>
        <a href="https://twitter.com">
          <TiSocialTwitterCircular />
        </a>
        <a href="https://linkedin.com">
          <AiOutlineLinkedin />
        </a>
      </div>
      <div className="footer_copyright">
        <small>&copy; VIJETH NARAYAN . All rights reserved.</small>
      </div>
    </footer>
  );
};

export default footer;
